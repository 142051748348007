<template>
    <div class="book-page">
        <swiper class="swiper book-panel" :options="swiperOption" ref="mySwiper">
            <swiper-slide>
                <div class="b1"></div>
            </swiper-slide>
            <swiper-slide>
                <div class="b2"></div>
            </swiper-slide>
            <swiper-slide>
                <div class="b3"></div>
            </swiper-slide>
            <swiper-slide>
                <div class="b4"></div>
            </swiper-slide>
            <swiper-slide>
                <div class="b5"></div>
            </swiper-slide>
            <swiper-slide>
                <div class="b6"></div>
            </swiper-slide>
            <swiper-slide>
                <div class="b7"></div>
            </swiper-slide>
            <swiper-slide>
                <div class="b8"></div>
            </swiper-slide>
            <swiper-slide>
                <div class="b9"></div>
            </swiper-slide>
            <swiper-slide>
                <div class="b10"></div>
            </swiper-slide>
            <swiper-slide>
                <div class="b11"></div>
            </swiper-slide>
            <swiper-slide>
                <div class="b12"></div>
            </swiper-slide>
            <swiper-slide>
                <div class="b13"></div>
            </swiper-slide>
            <swiper-slide>
                <div class="b14"></div>
            </swiper-slide>
            <swiper-slide>
                <div class="b15"></div>
            </swiper-slide>
            <swiper-slide>
                <div class="b16"></div>
            </swiper-slide>
            <swiper-slide>
                <div class="b17"></div>
            </swiper-slide>
            <swiper-slide>
                <div class="b18"></div>
            </swiper-slide>
            <swiper-slide>
                <div class="b19"></div>
            </swiper-slide>
            <swiper-slide>
                <div class="b20"></div>
            </swiper-slide>
        </swiper>
        <div class="guide" v-if="guide" @click="hideGuide"></div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/swiper.scss'

export default {
    name: 'Book',
    components:{
		Swiper,
		SwiperSlide
    },
    data(){
        return{
            guide: false,
            swiperOption: {
				// on: {
				// 	slideChange: (swiper) => {
				// 		this.zone = swiper.activeIndex
				// 	}
				// }
			}
        }
    },
    computed: mapState([
        'openMenu'
    ]),
    mounted(){
        this.guide = localStorage.getItem('book_guide') ? false : true
    },
	methods:{
        hideGuide(){
            this.guide = false
            localStorage.setItem('book_guide',true)
        }
	}
}
</script>
<style lang="scss">
@import "@/assets/styles/common.scss";
    .book-page{
        .book-panel{
            .b1{
                @include bg("book/1.jpg", 100%, 100vh);
            }
            .b2{
                @include bg("book/2.jpg", 100%, 100vh);
            }
            .b3{
                @include bg("book/3.jpg", 100%, 100vh);
            }
            .b4{
                @include bg("book/4.jpg", 100%, 100vh);
            }
            .b5{
                @include bg("book/5.jpg", 100%, 100vh);
            }
            .b6{
                @include bg("book/6.jpg", 100%, 100vh);
            }
            .b7{
                @include bg("book/7.jpg", 100%, 100vh);
            }
            .b8{
                @include bg("book/8.jpg", 100%, 100vh);
            }
            .b9{
                @include bg("book/9.jpg", 100%, 100vh);
            }
            .b10{
                @include bg("book/10.jpg", 100%, 100vh);
            }
            .b11{
                @include bg("book/11.jpg", 100%, 100vh);
            }
            .b12{
                @include bg("book/12.jpg", 100%, 100vh);
            }
            .b13{
                @include bg("book/13.jpg", 100%, 100vh);
            }
            .b14{
                @include bg("book/14.jpg", 100%, 100vh);
            }
            .b15{
                @include bg("book/15.jpg", 100%, 100vh);
            }
            .b16{
                @include bg("book/16.jpg", 100%, 100vh);
            }
            .b17{
                @include bg("book/17.jpg", 100%, 100vh);
            }
            .b18{
                @include bg("book/18.jpg", 100%, 100vh);
            }
            .b19{
                @include bg("book/19.jpg", 100%, 100vh);
            }
            .b20{
                @include bg("book/20.jpg", 100%, 100vh);
            }
        }

        .guide{
            position: fixed;
            top: 0;
            left: 0;
            // width: 100vw;
            // height: 100vh;
            @include bg('tips2.png',100vw,100vh);
            background-size: 347px 255px;
            z-index: 999;
        }
    }
</style>
